import {TypeAnimation} from "react-type-animation";

const YassinYounisTypingAnimation = () => {
    return (
        <TypeAnimation
            sequence={[
                // Same substring at the start will only be typed out once, initially
                'Yassin',
                1000, // wait 1s before replacing "Mice" with "Hamsters"
                'Younis',
                1000,
                'Yassin Younis',
                1000,
                'yassinyounis.com',
                1000,
            ]}
            wrapper="span"
            speed={50}
            className={"typing-animation"}
            repeat={Infinity}
        />
    )
}

export default YassinYounisTypingAnimation;