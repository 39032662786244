import { IconButton } from "@mui/joy";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const SocialMediaList = () => {

    const handleGithubClick = () => {
        window.open("https://github.com/Yassin-Younis");
    }

    const handleLinkedInClick = () => {
        window.open("https://www.linkedin.com/in/yassin-y/");
    }

    const handleEmailClick = () => {
        window.open("mailto:yasseenhaitham@gmail.com");
    }

    return (
        <div className={"social-media-list"}>
            <IconButton variant="outlined" className={"social-media-button"} onClick={handleGithubClick}>
                <FaGithub className={"social-media-icon"} />
            </IconButton>
            <IconButton variant="outlined" className={"social-media-button"} onClick={handleLinkedInClick}>
                <FaLinkedin className={"social-media-icon"} />
            </IconButton>
            <IconButton variant="outlined" className={"social-media-button"} onClick={handleEmailClick}>
                <MdEmail className={"social-media-icon"} />
            </IconButton>
        </div>
    );
};

export default SocialMediaList;
