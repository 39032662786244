import {IoLocation} from "react-icons/io5";

const LocationTag = ({name}) => {
    return (
        <div className={"location-tag"}>
            <IoLocation/>
            <div className={"location-tag-text"}>{name}</div>
        </div>
    )
}

export default LocationTag;