import {ParallaxBanner} from "react-scroll-parallax";
import sky from "./assets/px banner/sky.png";
import me from "./assets/px banner/me.png";
import ground from "./assets/px banner/ground.png";
import LocationTag from "./LocationTag";

const MainBannerImage = () => {
    const isMobile = window.innerWidth < 1000;

    const background = {
        image: sky,
        translateY: [0, 35],
        scale: [1.05, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
    };

    const headline = {
        translateY: [0, 0],
        translateX: [0, 30],
        scale: [1, 1.05, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
        expanded: true,
        image: me,
    };

    const foreground = {
        image: ground,
        translateY: [-50, 0],
        scale: [1.1, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
    };


    const gradientOverlay = {
        opacity: [0, 0.5],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
            <div className={'px-solid'}/>
        ),
    };


    const location = {
        scale: [1.2, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
        expanded: true,
        children: (
            <LocationTag name={"Rumkale, Şanlıurfa"}/>
        ),
    };


    return (
        <>
            {/*<div className={"simple-text"}>My latest trip to Rumkale, Türkiye, 2024 📷</div>*/}
            <ParallaxBanner layers={[background, foreground, headline, location, gradientOverlay]}
                            style={{height: isMobile ? "60vh" : "100vh"}}/>
        </>

    )
}

export default MainBannerImage;