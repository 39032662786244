import React, {useEffect} from 'react';
import './App.css';
import MainBannerImage from "./MainBannerImage";
import YassinYounisTypingAnimation from "./YassinYounisTypingAnimation";
import SocialMediaList from "./SocialMediaList";
import IAmTypingAnimation from "./IAmTypingAnimation";
import MyTimeline from "./MyTimeline";
import SecondaryBannerImage from "./SecondaryBannerImage";
import {Parallax, useParallax} from "react-scroll-parallax";
import platograph from "./assets/platographLogo.png"
import ThirdBannerImage from "./ThirdBannerImage";
import {Tooltip} from "@mui/joy";

const IntroPage = () => {
    return (
        <div className={"full-container"}>
            <YassinYounisTypingAnimation/>
            <SocialMediaList/>

        </div>
    )
}
const IAmPage = () => {
    const parallax = useParallax({
        opacity: [0.2, 0.1, 'easeOutQuad'],
        scale: [0.8, 1.1, 'easeInQuad'],
    });
    return (
        <div className={"mid-container"}>
            <div className={"outlined-headline"} ref={parallax.ref}>About Me</div>
            <IAmTypingAnimation/>
        </div>

    )
}

const TimelinePage = () => {
    const parallax = useParallax({
        scale: [0.8, 1.1, 'easeInQuad'],
        opacity: [0.2, 0.1, 'easeOutQuad'],
    });
    return (
        <>
            <div className={"mid-container"}>
                {/*<div className={"outlined-headline"} ref={parallax.ref}>Experience</div>*/}
                <div className={"outlined-headline"} ref={parallax.ref}>Experience</div>
                <h1>
                    And this is how my career is going so far...
                </h1>
            </div>
            <div className={'horizontal-line'}/>
            <MyTimeline/>
            <div className={'horizontal-line'} style={{marginBottom: 50}}/>
        </>
    )
}

const ProjectsPage = () => {
    const isMobile = window.innerWidth < 1000;

    const parallax = useParallax({
        scale: [0.8, 1.1, 'easeInQuad'],
        opacity: [0.2, 0.1, 'easeOutQuad'],
    });

    const parallax2 = useParallax({
        translateX: isMobile ? undefined : [-10, 10],
    });
    return (
        <>
            <div className={"small-container"}>
                {/*<div className={"outlined-headline"} ref={parallax.ref}>Experience</div>*/}
                <div className={"outlined-headline"} ref={parallax.ref}>Projects</div>
                <h1>
                    Currently I'm working on...
                </h1>
            </div>
            <div className={"full-container"} style={{flexDirection: !isMobile ? "row" : "column", height: "auto"}}
                 ref={parallax2.ref}>
                <div className={"project-container"}>
                    <img src={platograph} style={{width: "40%"}}/>
                    <div className={"platograph-title"}>Platograph</div>
                    <div className={"project-description"}>A minimalist platform for deep thinkers. Capture your
                        evolving ideas as 'claims,' creating a visual Platograph of your thought journey. Share
                        privately or publicly, engage in challenges, and explore diverse perspectives. Foster
                        intellectual growth in a space designed for mindful reflection, not daily addiction. Join us on
                        a quest for truth.
                    </div>
                </div>
                {/*<div className={"project-container"}>*/}
                {/*    <img src={platograph} style={{width: "40%"}}/>*/}
                {/*    <div className={"platograph-title"}>Bardiah</div>*/}
                {/*    <div className={"project-description"}>A minimalist platform for deep thinkers. Capture your*/}
                {/*        evolving ideas as 'claims,' creating a visual Platograph of your thought journey. Share*/}
                {/*        privately or publicly, engage in challenges, and explore diverse perspectives. Foster*/}
                {/*        intellectual growth in a space designed for mindful reflection, not daily addiction. Join us on*/}
                {/*        a quest for truth.*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

const SkillsPage = () => {
    const parallax = useParallax({
        scale: [0.8, 1.1, 'easeInQuad'],
        opacity: [0.2, 0.1, 'easeOutQuad'],
    });

    const skillsData = [
        {
            title: 'Photoshop',
            imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/1051px-Adobe_Photoshop_CC_icon.svg.png'
        },
        {
            title: 'Tailwind CSS',
            imageUrl: 'https://logowik.com/content/uploads/images/tailwind-css3232.logowik.com.webp'
        },
        {title: 'Flutter', imageUrl: 'https://cdn.iconscout.com/icon/free/png-256/flutter-2038877-1720090.png'},
        {title: 'Next.js', imageUrl: 'https://static-00.iconduck.com/assets.00/nextjs-icon-2048x2048-ex16l2k4.png'},
        {title: 'Nest.js', imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/a/a8/NestJS.svg'},
        {
            title: 'Express.js',
            imageUrl: 'https://ih1.redbubble.net/image.438908244.6144/bg,f8f8f8-flat,750x,075,f-pad,750x1000,f8f8f8.u2.jpg'
        },
        {title: 'TypeScript', imageUrl: 'https://cdn.iconscout.com/icon/free/png-256/typescript-1174965.png'},
        {title: 'Java', imageUrl: 'https://cdn.iconscout.com/icon/free/png-256/java-43-569305.png'},
        {title: 'C++', imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1822px-ISO_C%2B%2B_Logo.svg.png'},
        {title: 'MongoDB', imageUrl: 'https://cdn.icon-icons.com/icons2/2415/PNG/512/mongodb_original_logo_icon_146424.png'},
        {title: 'Docker', imageUrl: 'https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/97_Docker_logo_logos-512.png'},
        {title: 'Git', imageUrl: 'https://cdn.iconscout.com/icon/free/png-256/git-225996.png'},
    ];

    return (
        <>
            <div className={"mid-container"}>
                <div className={"outlined-headline"} ref={parallax.ref}>
                    Skills
                </div>
                <h1>A couple of things I've learned along the way...</h1>
            </div>
            <div className={"small-container"} style={{height: "auto"}}>
                <div className={"skill-container"}>
                    {skillsData.map((skill, index) => (
                        <Tooltip key={index} title={skill.title} variant="outlined">
                            <img src={skill.imageUrl} alt={skill.title} className={"skill-badge"}/>
                        </Tooltip>
                    ))}
                </div>
            </div>
        </>
    );
};

const ContactPage = () => {
    const parallax = useParallax({
        scale: [0.8, 1.1, 'easeInQuad'],
        opacity: [0.2, 0.1, 'easeOutQuad'],
    });

    return (
        <div className={"mid-container"}>
            <div className={"outlined-headline"} ref={parallax.ref}>
                Contact
            </div>
            <h1 style={{marginBottom: -5}}>Let's get in touch!</h1>
            <SocialMediaList/>
        </div>
    )
}

const Footer = () => {

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className={"footer"}>
            <YassinYounisTypingAnimation/>
            <div className={"footer-text"}>
                Copyright © 2024 Yassin Younis. All rights reserved.

                Unless otherwise indicated, all materials on this site are the property of Yassin Younis and may not be reproduced, distributed, transmitted, displayed, or otherwise used without the prior written permission of the owner.
            </div>
            <div onClick={scrollToTop} className={"blue-text-button"} >Navigate to top...</div>
        </div>
    )
}


const App = () => {

    return (
        <div style={{width: "100%"}}>
            <IntroPage/>
            <SecondaryBannerImage/>
            <IAmPage/>
            <MainBannerImage/>
            <TimelinePage/>
            <ThirdBannerImage/>
            <ProjectsPage/>
            <SkillsPage/>
            <ContactPage/>
            <div className={'horizontal-line'}/>
            <Footer/>
        </div>
    )
}

export default App;