import {ParallaxBanner} from "react-scroll-parallax";
import sky from "./assets/px banner/sky2.png";
import me from "./assets/px banner/me2.png";
import ground from "./assets/px banner/ground2.png";
import LocationTag from "./LocationTag";

const ThirdBannerImage = () => {
    const isMobile = window.innerWidth < 1000;

    const background = {
        image: sky,
        translateY: [0, 20],
        scale: [1.05, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
    };

    const headline = {
        translateY: [0, 25],
        translateX: [-15, 15],
        scale: [1.1, 1.5, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        image: me,
    };

    const foreground = {
        image: ground,
        scale: [1.5, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
    };


    const gradientOverlay = {
        opacity: [0, 1],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
            <div className={'px-solid'}/>
        ),
    };

    const location = {
        scale: [1.2, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
        expanded: true,
        children: (
            <LocationTag name={"Üç Güzeller, Kapadokya"}/>
        ),
    };
    return (
        <>
            {/*<div className={"simple-text"}>My latest trip to Rumkale, Türkiye, 2024 📷</div>*/}
            <ParallaxBanner layers={[background, foreground, headline, location, gradientOverlay]}
                            style={{height: isMobile ? "60vh" : "100vh"}}/>
        </>

    )
}

export default ThirdBannerImage;