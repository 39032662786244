import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {SiAdobephotoshop} from "react-icons/si";
import {FaLevelUpAlt, FaUniversity} from "react-icons/fa";
import {MdWork} from "react-icons/md";
import {IoIosTime} from "react-icons/io";

const elementStyle = {
    className: "vertical-timeline-element--work",
    contentStyle: {background: 'purple', color: 'white', borderRadius: '20px', fontFamily: "Poppins, Arial"},
    contentArrowStyle: {borderRight: '0px solid  purple'},
    iconStyle: {background: 'purple', color: '#fff'}
};

const TimelineElement = ({date, icon, title, subtitle, description}) => (
    <VerticalTimelineElement
        {...elementStyle}
        date={date}
        icon={icon}
    >
        <h3 className="vertical-timeline-element-title">{title}</h3>
        <i className="vertical-timeline-element-subtitle">{subtitle}</i>
        <p>{description}</p>
    </VerticalTimelineElement>
);

const MyTimeline = () => (
    <VerticalTimeline lineColor={"#2a2a2a"}>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentArrowStyle={{borderRight: '0px solid  purple'}}
            iconStyle={{background: 'purple', color: '#fff'}}
            icon={<IoIosTime/>}
        />
        <TimelineElement
            date="2017 - present"
            icon={<SiAdobephotoshop/>}
            title="Graphic Design Freelancer"
            subtitle="Remote"
            description="Worked on several graphic design projects from clients around the world. Gained first hand experience on design theory and client communication."
        />
        <TimelineElement
            date="September 2021"
            icon={<FaUniversity/>}
            title="Enrolled in Bilkent University"
            subtitle="Ankara, Turkey"
            description="Went abroad from my home country to study Computer Science and Engineering at Bilkent University."
        />
        <TimelineElement
            date="February 2023"
            icon={<MdWork/>}
            title="Landed My First Internship at YD Software"
            subtitle="Ankara, Turkey"
            description="
Contributed to building a real-time video conferencing app, handling both back-end (Node.js) and front-end development (React for web, React Native for phones). Explored Selective Forwarding Unit (SFU) architecture for scalable video conferencing. "/>
        <TimelineElement
            date="June 2023"
            icon={<MdWork/>}
            title="Joined Layermark for Another Internship"
            subtitle="Remote"
            description="Worked on a web application for a cryptocurrency platform, developed further knowledge with Next.js."
        />
        <TimelineElement
            date="August 2023"
            icon={<FaLevelUpAlt/>}
            title="Promoted to Junior Developer at Layermark"
            subtitle="Remote"
            description="Earned promotion to a Junior Developer. Developed skills in web front-end and structured large codebases using Node.js. Designed pages, integrated endpoints, and utilized the Keycloak authentication library. Managed a project with 100,000+ lines of code, implementing action buttons for automated CRUD operations. Gained fundamental database knowledge and collaborated with a cross-functional team."
        />
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentArrowStyle={{borderRight: '0px solid  purple'}}
            iconStyle={{background: 'purple', color: '#fff'}}
            icon={<IoIosTime/>}
        />
    </VerticalTimeline>
);

export default MyTimeline;