import { ParallaxBanner } from "react-scroll-parallax";
import backgroundImage from "./assets/px funny/coolbg.png";
import me from "./assets/px funny/mecool.png";
const SecondaryBannerImage = () => {
    const isMobile = window.innerWidth < 1000;
    const background = {
        image: backgroundImage,
        translateY: [0, 0],
        opacity: [1, 0.4],
        scale: [1.8, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
    };

    const headline = {
        translateX: [0, -20],
        shouldAlwaysCompleteAnimation: true,
        expanded: true,
        image: me,
    };

    const gradientOverlay = {
        opacity: [0, 0.5],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
            <div className={'px-gradient'}/>
        ),
    };
    return (
        <>
            {/*<div className={"simple-text"}>My latest trip to Rumkale, Türkiye, 2024 📷</div>*/}
            <ParallaxBanner layers={[background, headline, gradientOverlay]} style={{ height: isMobile ? "60vh" : "100vh" }} />
        </>

    )
};

export default SecondaryBannerImage;
