import {TypeAnimation} from "react-type-animation";

const IAmTypingAnimation = () => {
    return (
        <TypeAnimation
            sequence={[
                // Same substring at the start will only be typed out once, initially
                'I am a student 🧑‍🎓',
                1000, // wait 1s before replacing "Mice" with "Hamsters"
                'I am a developer 👨‍💻',
                1000,
                'I am an engineer 👨‍🔧',
                1000,
                'I am a designer 👨‍🎨',
                1000,
                'I am a creator 🧑‍🎨',
                1000,
            ]}
            wrapper="span"
            speed={50}
            className={"typing-animation"}
            repeat={Infinity}
        />
    )
}

export default IAmTypingAnimation;